.App {
    text-align: center;
    display: flex;
    justify-content: center;
}

.App-form {
    max-width: 50%;
}

.inv-field {
    height: 35px;
    box-sizing: border-box;
    width: 100%;
    padding: 5px;
    display: inline-block;
    box-shadow: none;
    font-weight: 500;
    font-size: 13px;
    border: 1px solid #dddddd;
    line-height: 12px;
    background: #fcfcfc;
    margin-bottom: 5px;
    background: linear-gradient(to right, white 50%, #fcfcfc 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    transition: all 300ms ease-in-out;
}

.inv-field-label {
    font-size: 13px;
    display: block;
    margin-bottom: 2px;
}

.braintree-hosted-fields-focused {
    border: 1px solid #64d18a;
    background-position: left bottom;
}

.braintree-hosted-fields-invalid {
    border: 1px solid #ed574a;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.site-title p {
    font-size: .9rem;
    line-height: 1.2;
    color: #495057;
    font-weight: 700;
    border-left: 1px solid #495057;
    padding-left: 1rem;
    margin-bottom: 0;
}

html, body, #root, .App {
    height: 100%;
    margin: 0;
}
.wrapper {
    display: flex;
    flex-flow: column;
    height: 100%;
    overflow: auto;
    background-color:$light;
}
flex-header {
    flex: 0 1 auto;
    top: 0;
    width: 100%;
    align-self: flex-start;
    position:relative;
}
flex-footer {
    flex: 0  1 auto;
}
.flex-content {
    flex: 1 1 auto;
}

.z-index-0 { z-index:0; }
.z-index-1 { z-index:1; }
.z-index-2 { z-index:2; }

.shadow-sm {
    -webkit-box-shadow: 0 .125rem .35rem rgba(0,0,0,.1);
    box-shadow: 0 .125rem .35rem rgba(0,0,0,.1);
}
 
.v-center {
    position:relative;
    top:40%;
    transform:translateY(-40%);
}


.btn {
    border-radius: 2px;
    padding: .5em .75em;
    text-transform: uppercase;
    font-size: 1em;
    font-weight: 700;
    text-decoration: none;
    color: #ffffff!important;
    background-color: #9e0442!important;
}

.btn:hover {
    background-color: #860338!important;
}
